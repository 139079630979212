<template>
  <header :class="headerClassList" class="fixed w-full z-30 top-0 transition duration-300">
    <div
      v-if="!props.customPage"
      class="w-full flex flex-wrap items-center justify-between py-5 bg-neutral-150 lg:bg-white"
    >
      <div class="lg:hidden container">
        <button
          class="flex items-center p-1"
          @click.prevent.stop="onToggleClick"
        >
          <NuxtIcon filled name="bars" class="fill-current text-2xl"/>
        </button>
      </div>
      <div
        :class="navContentClassList"
        class="
          w-full
          flex-grow mt-4 z-20
          lg:container lg:flex lg:items-center lg:w-auto lg:mt-0
        "
      >
        <nav
          class="flex-1 items-center text-center py-5 lg:flex lg:gap-x-6 lg:py-0"
        >
          <NuxtLink
            v-for="(item, index) in header.links"
            :key="index"
            :to="`/#${item.target}`"
            class="block font-semibold transition-all duration-500 decoration-primary-500 decoration-2 underline-offset-8 hover:underline py-2 active"
            :class="`${activeHeading.includes(item.target) ? 'text-pink-500' : 'text-current'}`"
            @click.prevent.stop="onToggleClick"
          >
            {{ item.label }}
          </NuxtLink>
        </nav>

        <nav class="flex items-center justify-center gap-x-4 py-5 lg:py-0">
          <UButton
            v-for="(social, index) in header.socials"
            :key="index"
            :to="social.link"
            size="sm"
            square
            variant="solid"
            target="_blank"
            class="bg-gray-700"
          >
            <NuxtIcon :name="social.name" class="text-2xl" />
          </UButton>
        </nav>
      </div>
    </div>
    <div v-else class="container py-5 bg-neutral-150 lg:bg-white">
      <NuxtLink :to="'/'">
        <NuxtIcon name="arrow-left" class="text-3xl" />
      </NuxtLink>
    </div>
  </header>
</template>

<script setup lang="ts">
  import { useWindowScroll } from '@vueuse/core'
  import { useScrollspy } from '~/composables/useScrollSpy'

  interface Props {
    data: {
      [key: string]: any
    }
    customPage?: boolean
  }

  const props = defineProps<Props>()
  const header =  toRef(props.data)

  const isOpen = ref(false)
  const { y } = useWindowScroll()

  const isSticky = computed(() => y.value > 10)
  const headerClassList = computed(() => isSticky.value ? 'bg-white shadow' : '')

  const navContentClassList = computed(() => {
    let classList = isSticky.value ? 'bg-white' : 'bg-transparent'
    if (!isOpen.value) {
      classList += ` hidden`
    }
    return classList
  })

  const onToggleClick = () => isOpen.value = !isOpen.value

  const nuxtApp = useNuxtApp()
  const { activeHeading, updateHeadings } = useScrollspy()

  nuxtApp.hooks.hookOnce('page:finish', () => {
    updateHeadings([
      document.querySelector('#hero'),
      document.querySelector('#features'),
      document.querySelector('#prices'),
      document.querySelector('#faq'),
      document.querySelector('#articles'),
      document.querySelector('#contacts'),
    ])
  })
  // const activeHeadings = (item) =>
</script>
