import { ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';

export function useScrollspy() {
  const observer = ref() as { value: IntersectionObserver | null };
  const activeHeading = ref('') as { value: string };

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    let mostRecentId = '';
    let mostRecentRatio = 0;

    entries.forEach((entry) => {
      const id = entry.target.id;

      if (entry.isIntersecting && entry.intersectionRatio > mostRecentRatio) {
        mostRecentId = id;
        mostRecentRatio = entry.intersectionRatio;
      }
    });

    activeHeading.value = mostRecentId;
  };

  const updateHeadings = (headings: (Element | null)[]) => {
    headings.forEach((heading) => {
      if (heading) {
        observer.value?.observe(heading);
      }
    });
  };

  watch(activeHeading, (val) => {
    if (val) {
      observer.value?.disconnect();
      observer.value = new IntersectionObserver(observerCallback);
      updateHeadings([
        document.querySelector(`#${val}`)
      ]);
    }
  });

  onBeforeUnmount(() => observer.value?.disconnect());

  return {
    activeHeading,
    updateHeadings,
  };
}
