<template>
  <footer class="bg-gray-900">
    <div class="container">
      <div class="w-full flex flex-col md:flex-row items-center py-8">
        <div class="flex-1 md:order-1">
          <nav class="flex items-center justify-center gap-x-4 py-5 lg:py-0">
            <UButton
              v-for="(social, index) in footer.socials"
              :key="index"
              :to="social.link"
              size="sm"
              square
              variant="solidInverse"
              target="_blank"
            >
              <NuxtIcon :name="social.name" class="text-3xl" />
            </UButton>
          </nav>
        </div>
        <div class="flex-1 text-center md:text-left">
          <MDC
            :value="footer.note"
            tag="div"
            class="prose prose-invert text-gray-300"
          />
          <NuxtLink
            :to="'/consent'"
            class="block font-semibold text-gray-300 hover:text-white mt-2"
          >
            {{ footer.consent }}
          </NuxtLink>
        </div>
        <div class="flex-1 flex justify-end py-4 order-1">
          <a
            href="#"
            class="flex items-center gap-x-2 text-sm font-semibold text-gray-300 hover:text-white"
          >
            <span>{{ footer.back }}</span>
            <NuxtIcon name="arrow-up" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
  interface Props {
    data: {
      [key: string]: any
    }
  }

  const props = defineProps<Props>()
  const footer = toRef(props.data)
</script>
