<template>
  <TheHeader v-if="layout" :data="layout.header" />
  <slot />
  <TheFooter v-if="layout" :data="layout.footer" />
</template>

<script setup lang="ts">
  interface Props {
    layout: Record<string, any>
  }

  const props = defineProps<Props>()
  const { layout } = toRefs(props)
  const meta = layout.value?.app?.meta ?? null

  useServerSeoMeta({
    title: meta?.title ?? '',
    description: meta?.description ?? '',
    ogTitle: meta?.title ?? '',
    ogDescription: meta?.description ?? '',
  })
</script>
